@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

$primary: #8743cc;
$dark: #191a1f;
$navbar-light-hover-color: #8743cc;
$navbar-light-active-color: #8743cc;

@import "~bootstrap/scss/bootstrap";

* {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;

  font-family: 'Rubik', sans-serif;
}

nav {
  width: 100%;
  // height: 5vh;
  z-index: 2;

  background-color: rgba(25, 26, 31, 1);
}

.nav-link {
  color: #8f9ba8;
}

.nav-link.active {
  font-weight: 600 !important;
  text-decoration: underline !important;
}


#root {
  background-color: rgba(25, 26, 31, 1);
}

.container {
  @media (min-width: 760px) {
    width: 800px;
  }
}

.container-main {
  height: 100vh;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

h1 {
  font-size: 3rem;
  background: linear-gradient(90deg, rgba(135,67,204,1) 0%, rgba(164,115,213,1) 24%, rgba(114,218,241,1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  margin-bottom: 4rem;

  font-weight: 500;
}

.container-main h2, .container-main a {
  font-size: 1rem;
  color: #f1f1f1;
  // letter-spacing: 5px;

  font-weight: 400;
}

p {
  font-size: 1rem;
  color: #8f9ba8;

  font-weight: 400;
}

.container-about, .container-experience, .container-work {
  min-height: 100vh;

  padding: 4rem 0px;
}

.container-about img {
  width: 40%;
  border-radius: 5px;

  clear: left;
  float: left;

  margin: 0 2rem 2rem 0;

  @media(max-width: 720px) {
    width: 50%;

    margin: 0 1.5rem 1.5rem 0;
  }
}

.container-work .content {
  border: 1px solid #8f9ba8;
  border-radius: 5px;

  margin: 2rem 0;
  padding: 1rem;
}

.container-work svg {
  width: 8rem;
  height: 8rem;
}

.container-work .btn div {
  height: 8rem;
}

.container-experience .content {
  // display: flex;
}

.container-experience svg {
  width: auto;
  height: 5rem;

  margin-bottom: 2rem;
}

.container-experience .two-content svg {
  margin-top: 3rem;
}

.container-contact {
  padding: 4rem 0 8rem 0;
}